/*
* index
*/
@import "base/_variables";


/*
* Slick
*/
.Pickup {
  > .Slide {
    margin-top: 20px;
    /*
    * スライド
    */
    figure {
      position: relative;
      a {
        position: relative;
        display: block;
        padding-top: 100%;
        width: 100%;
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: 50% 50%;
        }
      }
    }
  }
  .Banner {
    margin-left: -5px;
    margin-right: -5px;
    li {
      margin-top: 10px;
      padding-right: 5px;
      padding-left: 5px;
      a {
        display: block;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
  /*
  * 前後矢印
  */
  .slick-prev, .slick-next {
    position: absolute;
    top: calc(50% - 13px);
    width: 26px;
    height: 26px;
    text-align: center;
    background-color: #FFFFFF;
    border-radius: 50%;
    filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.4));
    z-index: 9999;
  }
  .slick-prev {
    left: 10px;
    @include pc() {
      left: -13px;
    }
  }
  .slick-next {
    right: 10px;
    @include pc() {
      right: -13px;
    }
  }
}
.Pickup > .Slide .slick-slide {
	width: 150px;
  margin-left: 10px;
}



/*pc
-------------------------------------------------------*/
@media print, screen and (min-width: 768px) {

	#Wrapper > *:nth-child(1).Side {
    width: 270px;
	}
	#Wrapper > #Content {
		width: 630px;
	}
	#Wrapper > *:nth-child(3).Side {
		width: 240px;
	}

	.Pickup > .flex {
		align-items: center;
	}
	.Pickup > .flex .btn2 {
		margin: 0;
	}

}


/*sphone
-------------------------------------------------------*/
@media screen and (max-width: 767px) {

	#Wrapper {
		display: block;
	}

	.Pickup p {
		font-size: 1.6rem;
		line-height: 2.4rem;
		font-weight: bold;
	}
	.Pickup > .Slide {
		width: 100vw;
		margin-left: calc(50% - 50vw);
		margin-right: calc(50% - 50vw);
	}
	.sAdvertise ul {
		padding-left: 1em;
		text-align: center;
	}

}

/*
* 特選ショップ情報
*/
.sectionSpecialty {
  ul {
    li {
      /*
      * 画像
      */
      figure {
        position: relative;
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: 50% 50%;
        }
      }
      .note {
        h3 {
          white-space: nowrap;
        }
      }
    }
  }
}

/*
* 右列下部バナー
*/
.sideSiteBanner {
  margin-top: 10px;
  div {
    margin-bottom: 10px;
  }
}